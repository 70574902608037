export default {
  omnistore: {
    searchToken: 'Ibf5bc10-2026-4a12-9750-4adac1cad893',
    platform: 'vtex'
  },
  abrand: {
    searchToken: '',
    platform: 'vtex'
  },
  afabula: {
    searchToken: ''
  },
  animale: {
    searchToken: '',
    platform: 'vtex'
  },
  avongroup: {
    searchToken: 'dc2e83a64b9d3f4fd81db5a88720c834',
    platform: 'vtex',
    searchReportId: 'dfb8b0c1-51f1-48ef-a517-de22831f5a10',
    showcaseReportId: 'bbe4c641-3784-4381-b931-91af726c491e',
    groupId: 'a837feac-f128-43f8-acf6-1ae8f4516819'
  },
  centralar: {
    searchToken: ''
  },
  cvcv2: {
    searchToken: ''
  },
  cea: {
    searchToken: '',
    platform: 'vtex'
  },
  estantevirtual: {
    searchToken: '',
    emailReportId: '84801bed-2557-4925-bf7f-1e15579f38ff',
    emailDatasetId: 'fdd30d24-31f4-4886-bfdc-ab6d32cb24bd',
    groupId: 'a837feac-f128-43f8-acf6-1ae8f4516819'
  },
  foxton: {
    searchToken: ''
  },
  offpremium: {
    searchToken: '',
    platform: 'vtex'
  },
  parquedpedro: {
    searchToken: '3ef565dd09683b7b9da064f32b32bc35',
    platform: 'vtex'
  },
  shopfacil: {
    searchToken: '2a22a9cd48e80eb1323b9c9dae61a31c',
    platform: 'vtex'
  },
  shopfacilfuncionario: {
    searchToken: '7e4f2c36fe47f4cff758df19ba842e69',
    platform: 'vtex'
  },
  infostore: {
    searchToken: '7b907d0e0fc57b890cfbd2f37f8b6327',
    platform: 'vtex'
  },
  submarinoviagens: {
    searchToken: ''
  },
  t4f: {
    searchToken: ''
  },
  viavini: {
    searchToken: ''
  },
  magalu: {
    searchToken: ''
  },
  olist: {
    searchToken: ''
  },
  olist_sandbox: {
    searchToken: ''
  },
  conectala: {
    searchToken: ''
  },
  conectala_b2w: {
    searchToken: ''
  },
  conectala_VIA: {
    searchToken: ''
  },
  conectala_ML: {
    searchToken: ''
  },
  conectala_CARREFOUR: {
    searchToken: ''
  },
  conectala_NM: {
    searchToken: ''
  },
  conectala_decathlon: {
    searchToken: ''
  },
  b2w: {
    searchToken: ''
  },
  martins: {
    searchToken: ''
  },
  smart: {
    searchToken: ''
  },
  efacil: {
    searchToken: ''
  },
  madeiramadeira: {
    searchToken: ''
  },
  vertem: {
    searchToken: ''
  },
  shoppingsmileshml: {
    searchToken: 'f75b5d091aa034669189e6cc645fad11',
    platform: 'vtex'
  },
  shoppingsmiles: {
    searchToken: '',
    platform: 'vtex'
  },
  next_qa: {
    searchToken: 'dcc3ead04b7988534e2a612db5412769',
    platform: 'vtex'
  },
  next: {
    searchToken: '4a8c671e296878d4a4f57b3e61faa6bc',
    platform: 'vtex'
  },
  ra_oficial: {
    searchToken: '16d8a9d60499859d4b38740a5a9c8a11'
  },
  crisbarros: {
    searchToken: '',
    platform: 'vtex'
  },
  mariafilo: {
    searchToken: '',
    platform: 'vtex'
  },
  oi: {
  searchToken: ''
  },
  carrefour: {
    searchToken: ''
  },
  inter: {
    searchToken: ''
  },
  gpa_hom: {
    searchToken: ''
  },
  gpa: {
    searchToken: ''
  },
  renner: {
    searchToken: ''
  },
  renner_sandbox: {
    searchToken: ''
  },
  camicado_sandbox: {
    searchToken: ''
  },
  camicado: {
    searchToken: ''
  },
  tauareservas: {
    searchToken: ''
  },
  rihappy: {
    searchToken: '',
    platform: 'vtex'
  },
  rihappyqa: {
    searchToken: '',
    platform: 'vtex'
  },
  leroymerlin: {
    searchToken: ''
  },
  farmrio: {
    searchToken: '',
    platform: 'vtex'
  },
  grandehotelaraxa: {
    searchToken: ''
  },
  b2bsearakitfesta: {
    searchToken: '',
    platform: 'vtex'
  },
  b2csearakitfesta: {
    searchToken: '',
    platform: 'vtex'
  },
  searacomerbem: {
    searchToken: '',
    platform: 'vtex'
  },
   vertem_porto: {
    searchToken: '',
    platform: 'vtex'
  },
  esfera: {
    searchToken: ''
  },
  esfera_sandbox: {
    searchToken: ''
  },
  lojamm: {
    searchToken: '',
    platform: 'vtex'
  },
  vertem_porto_oficial:  {
    searchToken: '',
    platform: 'vtex'
  },
  vertem_porto_pf:  {
    searchToken: '',
    platform: ''
  },
  hering:  {
    searchToken: '',
    platform: 'vtex'
  },
  sorteonline:  {
    searchToken: '',
    platform: 'vtex'
  },
  torratorra:  {
    searchToken: '',
    platform: 'vtex'
  },
  maistaua: {
    searchToken: ''
  },
  sorteonline01:  {
    searchToken: '',
    platform: 'vtex'
  },
  outletespacohering:  {
    searchToken: '',
    platform: 'vtex'
  },
  dzarm:  {
    searchToken: '',
    platform: 'vtex'
  },
  "farmlatam-ar": {
    searchToken: '',
    platform: 'vtex'
  },
  "farmlatam-cl": {
    searchToken: '',
    platform: 'vtex'
  },
  "farmlatam-co": {
    searchToken: '',
    platform: 'vtex'
  },
  "farmlatam-pe": {
    searchToken: '',
    platform: 'vtex'
  },
  "farmlatam-py": {
    searchToken: '',
    platform: 'vtex'
  },
  "farmlatam-uy": {
    searchToken: '',
    platform: 'vtex'
  },
  farmrioweb: {
    searchToken: '',
    platform: 'vtex'
  }
};
